import Cookies from "js-cookie";
import {shopify} from "./api";
import {CHECKOUT_CREATE_MUTATION} from "../queries/checkout";

const CHECKOUT_COOKIE_ID = 'checkoutId';

export function deleteCheckoutId() {
  return Cookies.remove(CHECKOUT_COOKIE_ID);
}

export async function getCheckoutId() {
  let checkoutId = Cookies.get(CHECKOUT_COOKIE_ID);

  if (!checkoutId) {
    const {data} = await shopify.mutate({mutation: CHECKOUT_CREATE_MUTATION});
    checkoutId = data.checkoutCreate.checkout.id;

    setCheckoutId(checkoutId);
  }

  return checkoutId;
}

function setCheckoutId(id) {
  Cookies.set(CHECKOUT_COOKIE_ID, id, { expires: 14 }); // 14 days
}
