import {combineReducers, configureStore} from "redux-starter-kit";
import checkout from "./slices/checkout";
import product from "./slices/product";
import products from "./slices/products";

export const actions = {
  checkout: checkout.actions,
  product: product.actions,
  products: products.actions,
};

const rootReducer = combineReducers({
  checkout: checkout.reducer,
  product: product.reducer,
  products: products.reducer,
});

export const initStore = (initialState = {}) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
  });
};
