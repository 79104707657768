import { createSlice } from "redux-starter-kit";

const initialState = {
  loading: false,
  error: null,
  setShippingLoading: false,
  setShippingError: null,
  data: {
    lineItems: {
      edges: []
    }
  },
};

export default createSlice({
  name: "checkout",

  initialState: initialState,

  reducers: {
    getCheckoutRequest: (state) => {
      state.loading = true;
      state.error = null;
    },

    getCheckoutFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.error;
    },

    getCheckoutSuccess: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },

    lineItemsReplaceRequest: state => {
      state.loading = true;
      state.error = null;
    },

    lineItemReplaceFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.error;
    },

    lineItemsReplaceSuccess: (state, { payload }) => {
      state.loading = false;
      state.data = {
        ...payload.data,
        // Delete the shipping info when line items change
        // This is because Shopify updates the shipping in the background and it will only be accurate next request
        availableShippingRates: null,
      };
    },

    setShippingRequest: state => {
      state.shippingLoading = true;
      state.setShippingError = null;
    },

    setShippingFailure: (state, { payload }) => {
      state.shippingLoading = false;
      state.setShippingError = payload.error;
    },

    setShippingSuccess: (state, { payload }) => {
      state.shippingLoading = false;
      state.data = payload.data;
    },
  },
});
