import {gql} from 'apollo-boost';

export const CHECKOUT_FRAGMENT = gql`
  fragment checkout on Checkout {
    id
    webUrl
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    shippingAddress {
      city
      province
      country
    }
    availableShippingRates {
      shippingRates {
        title
        priceV2 {
          amount
          currencyCode
        }
      }
    }
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          customAttributes {
            key
            value
          }
          variant {
            id
            title
            product {
              id
              title
              handle
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
                maxVariantPrice {
                  amount
                  currencyCode
                }
              }
              images(first: 1) {
                edges {
                  node {
                    altText
                    transformedSrc
                  }
                }
              }
              productType
              variants(first: 250) {
                edges {
                  node {
                    id
                    title
                    sku
                    selectedOptions {
                      name
                      value
                    }
                  }
                }
              }
            }
            image {
              transformedSrc
            }
            priceV2 {
              amount
              currencyCode
            }
          }
          quantity
        }
      }
    }
  }
`;

export const CHECKOUT_CREATE_MUTATION = gql`
  mutation checkoutCreate {
    checkoutCreate(input: {allowPartialAddresses: true}) {
      checkout {
        id
      }
    }
  }
`;

export const CHECKOUT_QUERY = gql`
  ${CHECKOUT_FRAGMENT}
  query checkout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...checkout
      }
    }
  }
`;

export const CHECKOUT_LINE_ITEMS_REPLACE_MUTATION = gql`
  ${CHECKOUT_FRAGMENT}
  mutation checkoutLineItemsReplace($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...checkout
      }
    }
  }
`;

export const CHECKOUT_SHIPPING_ADDRESS_MUTATION = gql`
  ${CHECKOUT_FRAGMENT}
  mutation checkoutShippingAddressUpdateV2($checkoutId: ID!, $shippingAddress: MailingAddressInput!) {
    # Make sure the checkout allows partial addresses
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: {allowPartialAddresses: true}) {
      checkout {
        id
      }
    }
    # Set the shipping address
    checkoutShippingAddressUpdateV2(checkoutId: $checkoutId, shippingAddress: $shippingAddress) {
      checkout {
        ...checkout
      }
    }
  }
`;
