import remove from 'lodash/remove';
import findIndex from 'lodash/findIndex';
import {actions} from '../../store';
import {shopify} from '../api';
import {
  CHECKOUT_LINE_ITEMS_REPLACE_MUTATION,
  CHECKOUT_QUERY,
  CHECKOUT_SHIPPING_ADDRESS_MUTATION
} from "../../queries/checkout";
import {deleteCheckoutId, getCheckoutId} from "../checkout";
import {getAttributeValue} from "../utilities/shopify";

function getLineItemsFromShopify(lineItems) {
  return lineItems.map(({node}) => {
    const bundleVariantId = getAttributeValue(node, '_bundleVariantId');

    return {
      variantId: node.variant.id,
      quantity: node.quantity,
      ...(bundleVariantId ? {bundleVariantId} : {})
    };
  });
}

function getLineItemsForShopify(lineItems) {
  return lineItems.map(({variantId, quantity, bundleVariantId = null}) => {
    const customAttributes = [];

    if (bundleVariantId) {
      customAttributes.push({
        key: '_bundleVariantId',
        value: bundleVariantId,
      })
    }

    return {variantId, quantity, customAttributes};
  });
}

export function handleCheckoutFailure(error) {
  return async dispatch => {
    console.error('Error occurred getting checkout', error);

    dispatch(actions.checkout.getCheckoutFailure(error));

    deleteCheckoutId();
    setTimeout(() => dispatch(getCheckout()), 2000); // Try again in 2 seconds
  }
}

export function getCheckout() {
  return async dispatch => {
    try {
      dispatch(actions.checkout.getCheckoutRequest());

      const checkoutId = await getCheckoutId();

      const variables = {
        checkoutId
      };

      let {data} = await shopify.query({
        query: CHECKOUT_QUERY,
        variables
      });

      if (!data || !data.node) {
        dispatch(handleCheckoutFailure({error: 'No checkout data returned'}));
        return;
      }

      dispatch(actions.checkout.getCheckoutSuccess({data: data.node}));
    } catch (error) {
      dispatch(actions.checkout.getCheckoutFailure(error));
    }
  };
}

export function replaceLineItems(variables) {
  return async dispatch => {
    try {
      dispatch(actions.checkout.lineItemsReplaceRequest());

      let {data} = await shopify.mutate({
        mutation: CHECKOUT_LINE_ITEMS_REPLACE_MUTATION,
        variables
      });

      if (!data || !data.checkoutLineItemsReplace) {
        dispatch(handleCheckoutFailure({error: 'No line item data returned'}));
        return;
      }

      dispatch(actions.checkout.lineItemsReplaceSuccess({data: data.checkoutLineItemsReplace.checkout}));
    } catch (error) {
      dispatch(actions.checkout.lineItemReplaceFailure({error}));
    }
  };
}

function addVariantLineItem(lineItems, variantId, quantity = 1, bundleVariantId = null) {
  let found = false;

  // Find the line items with a matching variant/bundle ID and increment it
  lineItems = lineItems.map((lineItem) => {
    // We're looking for an item as part of a bundle
    if (bundleVariantId) {
      if (lineItem.variantId === variantId && lineItem.bundleVariantId === bundleVariantId) {
        found = true;
        lineItem.quantity += quantity;
      }

      // Don't continue - we don't want to check for a single item since we have a bundle ID
      return lineItem;
    }

    // Otherwise we're just looking for a normal item
    // Make sure the line item isn't part of a bundle
    if (lineItem.variantId === variantId && !lineItem.bundleVariantId) {
      found = true;
      lineItem.quantity += quantity;
    }

    return lineItem;
  });

  // Item wasn't found, add it
  if (!found) {
    lineItems.push({
      variantId,
      quantity,
      ...(bundleVariantId ? {bundleVariantId} : {})
    });
  }

  return lineItems;
}

function addBundleLineItems(lineItems, bundleVariantId, quantity, subItemVariantIds = []) {
  // Add each bundle sub-item as an individual line item, but grouped by the parent bundle ID
  return subItemVariantIds.reduce((currentLineItems, subItemVariantId) => {
    return addVariantLineItem(currentLineItems, subItemVariantId, quantity, bundleVariantId);
  }, lineItems);
}

export function addLineItem(variantId, quantity, subItemVariantIds = []) {
  return async (dispatch, getState) => {
    const checkoutId = await getCheckoutId();
    let lineItems = getLineItemsFromShopify(getState().checkout.data.lineItems.edges);

    lineItems = subItemVariantIds.length > 0
      ? addBundleLineItems(lineItems, variantId, quantity, subItemVariantIds)
      : addVariantLineItem(lineItems, variantId, quantity);

    // Format line items for shopify
    lineItems = getLineItemsForShopify(lineItems);

    dispatch(replaceLineItems({checkoutId, lineItems}));
  };
}

function updateVariantLineItem(lineItems, variantId, quantity = 1) {
  return lineItems.map(lineItem => {
    // Update if the variant matches and it's not part of a bundle
    if (lineItem.variantId === variantId && !lineItem.bundleVariantId) {
      lineItem.quantity = quantity
    }

    // Update if it's part of a bundle
    if (lineItem.bundleVariantId === variantId) {
      lineItem.quantity = quantity
    }

    return lineItem;
  })
}

export function updateQuantity(variantId, quantity) {
  return async (dispatch, getState) => {
    const checkoutId = await getCheckoutId();
    let lineItems = getLineItemsFromShopify(getState().checkout.data.lineItems.edges);

    lineItems = updateVariantLineItem(lineItems, variantId, quantity);

    // Format line items for shopify
    lineItems = getLineItemsForShopify(lineItems);

    dispatch(replaceLineItems({checkoutId, lineItems}));
  };
}

function removeVariantLineItem(lineItems, variantId) {
  remove(lineItems, lineItem => {
    // Remove if the variant matches and it's not part of a bundle
    if (lineItem.variantId === variantId && !lineItem.bundleVariantId) {
      return true
    }

    // Remove if it's part of a bundle
    return lineItem.bundleVariantId === variantId;
  });

  return lineItems;
}

export function removeLineItem(variantId) {
  return async (dispatch, getState) => {
    const checkoutId = await getCheckoutId();
    let lineItems = getLineItemsFromShopify(getState().checkout.data.lineItems.edges);

    lineItems = removeVariantLineItem(lineItems, variantId);

    // Format line items for shopify
    lineItems = getLineItemsForShopify(lineItems);

    dispatch(replaceLineItems({checkoutId, lineItems}));
  };
}

export function setShippingAddress(suburb, state) {
  return async dispatch => {
    try {
      dispatch(actions.checkout.setShippingRequest());

      const checkoutId = await getCheckoutId();

      const variables = {
        checkoutId,
        shippingAddress: {
          city: suburb,
          province: state,
          country: "Australia"
        }
      };

      let {data} = await shopify.mutate({
        mutation: CHECKOUT_SHIPPING_ADDRESS_MUTATION,
        variables
      });

      if (!data || !data.checkoutShippingAddressUpdateV2) {
        dispatch(handleCheckoutFailure({error: 'No shipping data returned'}));
        return;
      }

      dispatch(actions.checkout.setShippingSuccess({data: data.checkoutShippingAddressUpdateV2.checkout}));
    } catch (error) {
      dispatch(actions.checkout.setShippingFailure({error}));
    }
  };
}
