import { createSlice } from "redux-starter-kit";

const initialState = {
  firstPage: {
    loading: true,
    error: null,
  },
  nextPage: {
    loading: false,
    error: null,
  },
  data: null,
};

export default createSlice({
  name: "products",

  initialState: initialState,

  reducers: {
    getFirstPageRequest: () => initialState,

    getFirstPageFailure: (state, { payload }) => {
      state.firstPage.loading = false;
      state.firstPage.error = payload.error;
    },

    getFirstPageSuccess: (state, { payload }) => {
      state.firstPage.loading = false;
      state.data = payload.data;
    },

    getNextPageRequest: state => {
      state.nextPage.loading = true;
      state.nextPage.error = null;
    },

    getNextPageFailure: (state, { payload }) => {
      state.nextPage.loading = false;
      state.nextPage.error = payload.error;
    },

    getNextPageSuccess: (state, { payload }) => {
      state.nextPage.loading = false;
      state.data.edges = state.data.edges.concat(payload.data.edges);
      state.data.pageInfo.hasNextPage = payload.data.pageInfo.hasNextPage;
    },
  },
});
