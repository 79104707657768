import { createSlice } from "redux-starter-kit";

const initialState = {
  loading: true,
  error: null,
  data: null,
};

export default createSlice({
  name: "product",

  initialState: initialState,

  reducers: {
    getRequest: () => initialState,

    getFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.error;
    },

    getSuccess: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },
  },
});
